import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import MapPin from "../../assets/Images/map_circle.webp";

export default function MapReportView({
  position,
  zoom,
  setPosition,
  mapContainerStyle,
}) {
  const [mapCenter, setMapCenter] = useState(position); // State to hold map center
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
  });
  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // Update map center when position prop changes (e.g., if position is updated externally)
  useEffect(() => {
    setMapCenter(position);
  }, [position]);

  // Function to update marker position at the center of the map viewport
  const updateMarkerPosition = () => {
    if (map) {
      const center = map.getCenter().toJSON();
      setPosition(center);
      setMapCenter(center);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {mapCenter && isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter}
          zoom={zoom}
          draggable={true}
          onDragEnd={() => {
            updateMarkerPosition();
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            fullscreenControl: false,
          }}
        ></GoogleMap>
      ) : (
        <></>
      )}
      <div className="center-marker">
        <img src={MapPin} alt="img" />
      </div>
    </div>
  );
}
