import { Col, Container, Row } from "react-bootstrap";
import styles from "./YourReport.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/Loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MapView from "../../../commonComponent/GoogleMap/MapView";
import { FaRegFlag } from "react-icons/fa";
import { SlEye } from "react-icons/sl";
import TrendRed from "../../../assets/Images/TrendRed.svg";
import BookmarkRed from "../../../assets/Images/BookmarkRed.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaChevronRight } from "react-icons/fa";
import Slider from "react-slick";
import SublyApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import { FaCirclePlus } from "react-icons/fa6";
import Form from "react-bootstrap/Form";
import { FaRegCircleCheck } from "react-icons/fa6";
import {
  FIELD_TYPE,
  LOCATION,
  PRIVATE_POFILE,
  REPORT_AS,
  active,
  inactive,
  isLimit,
} from "../../../utils/Constants";
import {
  b64toBlob,
  formatDate,
  formatDateTimeSlider,
  formatTime,
  formatUpdatedDate,
  formatUpdatedTime,
  getRandom,
} from "../../../utils/manageConstant";
import { useForm } from "react-hook-form";
import { ImCross } from "react-icons/im";
import FlagPopup from "./Flag/FlagPopup";
import FlagClosePopup from "./Flag/FlagClosePopup";
import chatMessageIcon from "../../../assets/Images/chatMessageIcon.svg";
import getIconByCategoryType from "../../../commonComponent/CategoryIcons";
import ReactPlayer from "react-player";
import CropImg from "../YourProfile/CropImg";
import mime from "mime";
import {
  updateSettingCount,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import CurrentLocation from "../../../assets/Images/Combined Shape.svg";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import anonymous from "../../../assets/Images/anonymous.svg";
import { FaRegEdit } from "react-icons/fa";
import ShareLink from "./ShareLink";
import { Helmet } from "react-helmet";

import branch from "branch-sdk";
import { v4 as uuidv4 } from "uuid";
import { useJsApiLoader } from "@react-google-maps/api";
import LazyLoadImageComponent from "../../../utils/LazyLoading/LazyLoadImage";

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.arrrowLeftReportDetails} onClick={onClick}>
      <IoIosArrowBack />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.arrrowRightReportDetails} onClick={onClick}>
      <IoIosArrowForward />
    </div>
  );
};
<FaRegCircleCheck />;
function ReportDetails() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
  });

  const [branchInitialize, setBranchInitialize] = useState(false);

  useEffect(() => {
    branch.init(process.env.REACT_APP_BRANCH_IO_KEY, (err, data) => {
      if (err) {
        console.error("Branch initialization error:", err);
      } else {
        setBranchInitialize(true);
      }
    });
  }, []);

  const generateUniqueAlias = () => {
    var uniqueId = uuidv4().replace(/-/g, "").substring(0, 5);
    return `report/${uniqueId}`;
  };

  const generateDeepLink = () => {
    const linkData = {
      channel: "email",
      feature: "referral",
      alias: generateUniqueAlias(),
      data: {
        $fallback_url: `https://www.crimespotter.app${routeName}`, // Redirect if the app is not installed
      },
    };

    branch.link(linkData, (err, link) => {
      if (err) {
        console.error("Branch link error:", err);
      } else {
        setGenerateUrl(link);
      }
    });
  };

  useEffect(() => {
    if (branchInitialize) {
      generateDeepLink();
    }
  }, [branchInitialize]);

  const routeLocation = useLocation();
  const routeName = routeLocation.pathname;
  const [generateUrl, setGenerateUrl] = useState("");

  const { t } = useTranslation();

  // comment scroll
  const scrollRef = useRef(null);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { userToken, currentUser } = useSelector((state) => state.user);
  const { userData } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [center, setCenter] = useState({
    location: LOCATION.LOC,
    lat: LOCATION.LAT,
    lng: LOCATION.LONG,
  });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [reportDetails, setReportDetails] = useState();
  const [profileDetails, setProfileDetails] = useState([]);

  const [isFollowing, setIsFollowing] = useState(0);
  const [commentData, setCommentData] = useState([]);
  const [commentDataList, setCommentList] = useState();
  const [comment, setComment] = useState("");
  const [userId, setUserId] = useState();
  const [offSet, setOffSet] = useState(0);
  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [reportId, setReportId] = useState("");

  const [timeLineData, setTimeLineData] = useState([]);
  const [totalReports, setTotalReports] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoThumb, setVideoThumb] = useState(null);

  // --flag popup show
  const [flagShow, setFlagShow] = useState(false);
  const flagPopupHandleShow = () => setFlagShow(true);
  const flagPopupHandleClose = () => setFlagShow(false);

  // --thanksflagShow popup show
  const [thanksflagShow, setThanksflagShow] = useState(false);
  const ThanksflagHandleShow = () => setThanksflagShow(true);
  const ThanksflagHandleClose = () => setThanksflagShow(false);

  const [mapList, setMapList] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const [isCropper, setIsCropper] = useState(false);

  const [deleteRederect, setDeleteRederect] = useState("");

  const [detailsWithHashtags, setDetailsWithHashtags] = useState("");

  const [reportLocation, setReportLocation] = useState(null);

  const containerRef = useRef(null);
  const handleCropComplete = () => {
    setShowCrop(false);
  };

  const IMAGE_SIZE_MB = 10;
  const VIDEO_SIZE_MB = 200;
  const MIN_IMAGE_WIDTH = 150;
  const MIN_IMAGE_HEIGHT = 150;
  const MAX_IMAGE_WIDTH = 4000;
  const MAX_IMAGE_HEIGHT = 4000;
  const VALID_VIDEO_EXTENSIONS = ["mp4", "MP4", "3gp", "3GP"];

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const fileType = file.type.startsWith("video") ? "video" : "image";

    const fileData = {
      url: URL.createObjectURL(file),
      type: fileType,
      file: file,
    };

    if (fileType === "image") {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const { width, height } = img;

        if (width < MIN_IMAGE_WIDTH) {
          Toster(t("IMAGE_WIDTH_MUST_BE_150px"), "error");
          return;
        }
        if (width > MAX_IMAGE_WIDTH) {
          Toster(t("IMAGE_WIDTH_MUST_BE_4000px"), "error");
          return;
        }
        if (height < MIN_IMAGE_HEIGHT) {
          Toster(t("IMAGE_HEIGHT_MUST_BE_150px"), "error");
          return;
        }
        if (height > MAX_IMAGE_HEIGHT) {
          Toster(t("IMAGE_HEIGHT_MUST_BE_4000px"), "error");
          return;
        }

        // Check image file size
        if (file.size > IMAGE_SIZE_MB * 1024 * 1024) {
          Toster(t("IMAGE_SIZE_MUST_BE_10MB"), "error");
          return;
        }

        setIsCropper(true);
        setPreviewImage(img.src);
        setImageSrc(img.src);
        setSelectedImage(file);
        setSelectedVideo("");
        setVideoThumb("");
      };
    } else if (fileType === "video") {
      const fileExtension = file.name.split(".").pop();
      if (!VALID_VIDEO_EXTENSIONS.includes(fileExtension)) {
        Toster(t("INVALID_VIDEO_FORMAT_MP4_3GP_ONLY"), "error");
        return;
      }

      const videoElement = document.createElement("video");
      videoElement.src = fileData.url;

      videoElement.onloadedmetadata = async () => {
        // Check video dimensions
        if (videoElement.videoWidth < MIN_IMAGE_WIDTH) {
          Toster(t("VIDEO_WIDTH_MUST_BE_150px"), "error");
          return;
        }
        if (videoElement.videoWidth > MAX_IMAGE_WIDTH) {
          Toster(t("VIDEO_WIDTH_MUST_BE_4000px"), "error");
          return;
        }
        if (videoElement.videoHeight < MIN_IMAGE_HEIGHT) {
          Toster(t("VIDEO_HEIGHT_MUST_BE_150px"), "error");
          return;
        }
        if (videoElement.videoHeight > MAX_IMAGE_HEIGHT) {
          Toster(t("VIDEO_HEIGHT_MUST_BE_4000px"), "error");
          return;
        }

        // Check video file size
        if (file.size > VIDEO_SIZE_MB * 1024 * 1024) {
          Toster(t("VIDEO_SIZE_MUST_BE_200MB"), "error");
          return;
        }

        const videoThumbRes = await handleExtractFrame(fileData?.file);
        setSelectedVideo(fileData);
        setPreviewImage("");
        setImageSrc("");
        setSelectedImage("");
        setIsCropper(false);
      };
    }
  };

  useEffect(() => {
    if (isCropper) {
      setShowCrop(true);
    }
  }, [isCropper]);

  const handleClose = () => {
    setShowCrop(false);
  };

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  const [page, setPage] = useState(1);

  const loadMoreReports = () => {
    setPage((prevPage) => prevPage + 1);
    CommentList(reportDetails.reportID, page + 1, offSet + 10);
    setOffSet((prevPage) => prevPage + 10);
  };

  const handleEdit = (item) => {
    navigate("/new-report", {
      state: { id: reportDetails && reportDetails.reportID, isUpdate: true },
    });
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  // slider
  const settingsNew = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    afterChange: (current) => setActivePageIndex(current),
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // --report details
  async function getReportDetails(id) {
    setLoader(true);
    userToken &&
      (await SublyApi.reportDetails(userToken, id).then((response) => {
        setLoader(false);
        const errormsg = response && response.data && response.data.message;
        if (
          response &&
          response.data &&
          response.status_code === STATUS_CODES.SUCCESS
        ) {
          setReportDetails(response.data.report);
          setReportId(response.data.report?.reportID);
          setDeleteRederect(response?.data?.data_found);
          setCenter({
            location: response.data.report?.location,
            lat: parseFloat(response.data.report?.latitude),
            lng: parseFloat(response.data.report?.longitude),
          });
          setMarkerPosition({
            location: response.data.report?.location,
            lat: parseFloat(response.data.report?.latitude),
            lng: parseFloat(response.data.report?.longitude),
          });
          getGeocode(
            response.data.report?.latitude,
            response.data.report?.longitude
          );
        } else if (
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "ACCOUNT_DISABLED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "SESSION_EXPIRED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
          Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
        } else {
          Toster(t(errormsg), "error");
        }
      }));
  }

  useEffect(() => {
    // Check if data_found is false and navigate
    if (deleteRederect === false) {
      navigate("/your-report");
    }
  }, [deleteRederect, navigate]);

  useEffect(() => {
    if (id) {
      getReportDetails(id);
    }
  }, [id]);

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  // BookmarkCount
  async function BookmarkCount(id, action) {
    setLoader(true);
    let requestData = new FormData();
    requestData.append("action", action);

    await SublyApi.UpdateBookmark(userToken, id, requestData).then(
      (response) => {
        setLoader(false);
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          getReportDetails(id);
          const successMessage =
            action === active
              ? t("REPORT_BOOKMARKED_SUCCESSFULL")
              : t("REPORT_UNBOOKMARKED_SUCCESSFULL");
          Toster(successMessage, "success");
          if (action == active) {
            dispatch(
              updateSettingCount({
                keyName: "bookmarked_count",
                value: parseInt(userData.bookmarked_count) + 1,
              })
            );
          } else {
            if (parseInt(userData.bookmarked_count) > 0) {
              dispatch(
                updateSettingCount({
                  keyName: "bookmarked_count",
                  value: parseInt(userData.bookmarked_count) - 1,
                })
              );
            }
          }
        } else if (
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "ACCOUNT_DISABLED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "SESSION_EXPIRED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
          Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
        } else {
          Toster(t(errormsg), "error");
        }
      }
    );
  }

  // TrendCount api
  async function TrendCount(id, action) {
    setLoader(true);
    let requestData = new FormData();
    requestData.append("action", action);

    await SublyApi.UpdateTrend(userToken, id, requestData).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        getReportDetails(id);
        const successMessage =
          action === active
            ? t("REPORT_TREND_SUCCESSFULL")
            : t("REPORT_UNTREND_SUCCESSFULL");
        Toster(successMessage, "success");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  async function FollowersCount(followId) {
    setLoader(true);
    await SublyApi.followingUpdate(userToken, followId).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        getReportDetails(id);
        const successMessage =
          isFollowing == 1
            ? t("PROFILE_UNFOLLOW_SUCCESSFULL")
            : t("PROFILE_FOLLOW_SUCCESSFULL");
        Toster(successMessage, "success");

        if (isFollowing == 0) {
          dispatch(
            updateSettingCount({
              keyName: "following_count",
              value: parseInt(userData?.following_count) + 1,
            })
          );
        } else {
          if (parseInt(userData?.following_count) > 0) {
            dispatch(
              updateSettingCount({
                keyName: "following_count",
                value: parseInt(userData?.following_count) - 1,
              })
            );
          }
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  async function PublicProfileList(id) {
    await SublyApi.PublicProfile(userToken, id).then((response) => {
      const errormsg = response && response.data && response.data.message;

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setProfileDetails(response.data.userdetails);
        setUserId(response?.data?.userdetails[0]?.userID);
        setIsFollowing(response?.data?.userdetails[0]?.follow_by_you);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  // comment api
  async function CommentList(id, page = 1, offset = 0) {
    await SublyApi.getComment(id, isLimit, offset).then((response) => {
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response.data.comments;
        setTotalReports(response?.data?.total_reports);
        setCommentList(response.data);
        if (sortedReports && sortedReports.length > 0) {
          if (page == 1) {
            setCommentData(sortedReports);
          } else {
            setCommentData((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setCommentData([]);
        }
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [commentData]);

  // comment list
  useEffect(() => {
    if (reportDetails && reportDetails.reportID) {
      CommentList(reportDetails.reportID);
    }
  }, [reportDetails]);

  useEffect(() => {
    if (reportDetails && reportDetails.reported_by_id) {
      PublicProfileList(reportDetails.reported_by_id);
    }
  }, [reportDetails]);

  useEffect(() => {
    mapListShow();
  }, [reportDetails]);

  const getGeocode = async (latitude, longitude) => {
    const { google } = window;
    if (isLoaded && google && latitude && longitude) {
      const geocoder = new google.maps.Geocoder();
      const latLng = new google.maps.LatLng(latitude, longitude);

      try {
        const results = await new Promise((resolve, reject) => {
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK" && results[0]) {
              resolve(results);
            } else {
              reject(status);
            }
          });
        });

        const addressComponents = results[0].address_components;

        // Extracting specific address details
        const locality = addressComponents.find((component) =>
          component.types.includes("locality")
        )?.long_name;

        const region = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        const postalCode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        )?.long_name;

        const country = addressComponents.find((component) =>
          component.types.includes("country")
        )?.long_name;

        // Get the full formatted address
        const formattedAddress = results[0]?.formatted_address;

        // Set state with the extracted values
        setReportLocation({
          addressLocality: locality,
          addressRegion: region,
          postalCode: postalCode,
          addressCountry: country,
        });
      } catch (error) {
        console.error("Error fetching address details:", error);
      }
    }
  };

  // ------get #hage tag-manage--
  useEffect(() => {
    if (reportDetails && reportDetails.details) {
      const detailsWithHashtags = reportDetails.details.replace(
        /#\w+/g,
        (match) => `<span style="color: red;">${match}</span>`
      );
      reportDetails.detailsWithHashtags = detailsWithHashtags;
      setDetailsWithHashtags(detailsWithHashtags);
    }
  }, [reportDetails]);

  useEffect(() => {
    const container = containerRef.current;

    // Only add event listener if container is available
    if (container) {
      const handleSpanClick = (event) => {
        if (event.target.tagName === "SPAN") {
          const tag = event.target.innerText;
          navigate(`/search-result?tagName=${tag.replace(/#/g, "")}`);
        }
      };

      container.addEventListener("click", handleSpanClick);

      // Cleanup the event listener on component unmount
      return () => {
        container.removeEventListener("click", handleSpanClick);
      };
    }
  }, [detailsWithHashtags]);

  if (!reportDetails || !reportDetails.report_attachments) {
    return null;
  }
  const imageAttachments = reportDetails.report_attachments.filter(
    (item) => item.attachment_thumb !== null
  );
  const videoAttachments = reportDetails.report_attachments.filter(
    (item) => item.video_thumb_url !== null
  );

  const handleCommentChange = (e) => {
    const value = e.target.value;

    if (value.trim() === "") {
      setComment("");
    } else {
      setComment(value);
    }

    // setComment(e.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleExtractFrame = (videoFile) => {
    if (!videoFile) return;

    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(videoFile);
    videoElement.crossOrigin = "anonymous";
    videoElement.muted = true; // Mute the video for autoplay
    videoElement.play(); // Start playing the video to ensure metadata is loaded
    videoElement.onloadeddata = () => {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      // Ensure the video is loaded and can be drawn
      videoElement.currentTime = 0; // Set to the first frame

      videoElement.onseeked = () => {
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(async (blob) => {
          // Send the image blob to the API
          const requestData = new FormData();
          requestData.append("attachment_thumb", blob, "frame.jpg");
          setVideoThumb(requestData.get("attachment_thumb"));

          return requestData.get("attachment_thumb");
        }, "image/jpeg");
      };
    };
  };

  // chat br tage manage
  const ChatTextBrTageFormate = (text) => {
    if (!text) return "";
    return text.replace(/\r\n|\r|\n/g, "<br />");
  };

  // PostComment
  async function chatPost() {
    let requestData = new FormData();
    requestData.append("comment", comment);

    if (isCropper == true) {
      var blockCrop = previewImage && previewImage.split(";");
      var cropContentTypes = blockCrop[0]?.split(":")[1];
      var blockRealData = blockCrop[1].split(",")[1];
      var cropBlobImg = b64toBlob(blockRealData, cropContentTypes);

      requestData.append(
        "attachment_file",
        cropBlobImg,
        getRandom() + "." + mime.getExtension(cropBlobImg.type)
      );
    } else if (selectedVideo) {
      // Append video file

      requestData.append("video_thumb", videoThumb);
      requestData.append("attachment_file", selectedVideo.file);
    }

    setLoader(true);
    await SublyApi.PostComment(
      userToken,
      reportDetails.reportID,
      requestData
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `40px`;
        getReportDetails(reportDetails.reportID);
        setComment("");
        setPreviewImage(null);
        setSelectedImage(null);
        setShowCrop(false);
        setIsCropper(false);
        handleRemoveMedia();
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  const onSubmit = (data) => {
    chatPost(data);
  };

  const handleRemoveMedia = () => {
    setPreviewImage(null);
    setSelectedImage(null);
    setShowCrop(false);
    setIsCropper(false);
    setSelectedVideo(null);
    setVideoThumb(null);
  };

  const TimeLineViewProfile = (id) => {
    navigate(`/profile-detail/${id}`);
  };

  const TimeLineViewDetails = (id) => {
    navigate(`/report/${id}`);
  };

  // -----map list

  async function mapListShow(
    latitude = "",
    longitude = "",
    sdate = "",
    duration = "",
    edate = "",
    categoryId = ""
  ) {
    try {
      const response = await SublyApi.getReportMapList(
        userToken,
        Number(reportDetails.latitude),
        Number(reportDetails.longitude),
        sdate,
        duration,
        edate,
        categoryId
      );

      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const reports = response && response.data && response.data.reports;
        if (reports && reports.length > 0) {
          setMapList(reports);
          setTimeLineData(reports);
        } else {
          setMapList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    } catch (error) {}
  }

  const handleCircleClick = () => {
    if (reportDetails?.reportID) {
      setCenter({
        location: reportDetails?.location,
        lat: parseFloat(reportDetails?.latitude),
        lng: parseFloat(reportDetails?.longitude),
      });
    }
  };

  // Full schema markup
  const attachmentsSchema = reportDetails?.report_attachments.map(
    (attachment) => {
      if (attachment.video_thumb_url !== null) {
        return {
          "@type": "VideoObject",
          name: attachment.attachment_name,
          contentUrl: attachment.attachment_url,
          thumbnailUrl: attachment.video_thumb_url,
        };
      } else {
        return {
          "@type": "ImageObject",
          name: attachment.attachment_name,
          contentUrl: attachment.attachment_url,
          thumbnailUrl: attachment.attachment_thumb,
        };
      }
    }
  );

  const date = reportDetails?.date;
  const time = reportDetails?.time;

  // Combine date and time into a single string in UTC format
  const dateTimeString = `${date}T${time}Z`;

  // Convert the combined string into a Date object
  const dateTime = new window.Date(dateTimeString);

  return (
    <div className="main">
      <Helmet>
        <title>Crimespotter : {reportDetails?.category_name}</title>
        <script type="application/ld+json">
          {`
          {
          "@context": "https://schema.org",
          "@type": "Report",
          "reportNumber": ${JSON.stringify(reportDetails?.reportID, null, 2)},
          "reportType": "Crime Report",
          "dateReported": ${JSON.stringify(dateTime, null, 2)},
          "locationCreated": {
            "@type": "Place",
          "address": {
            "@type": "PostalAddress",
          "streetAddress": ${JSON.stringify(reportDetails?.location, null, 2)},
           "addressLocality": ${JSON.stringify(
             reportLocation?.addressLocality,
             null,
             2
           )},
           "addressRegion": ${JSON.stringify(
             reportLocation?.addressRegion,
             null,
             2
           )},
           "postalCode": ${JSON.stringify(reportLocation?.postalCode, null, 2)},
           "addressCountry": ${JSON.stringify(
             reportLocation?.addressCountry,
             null,
             2
           )},
        },
          "geo": 
          {
            "@type": "GeoCoordinates",
            "latitude": ${JSON.stringify(reportDetails?.latitude, null, 2)},
            "longitude": ${JSON.stringify(reportDetails?.longitude, null, 2)}
          }
      },
          "description": ${JSON.stringify(reportDetails?.details, null, 2)},
          "category": 
          {
            "@type": "Thing",
            "name": ${JSON.stringify(
              reportDetails?.parent_category_name,
              null,
              2
            )},
           "identifier": ${JSON.stringify(
             reportDetails?.parent_category_id,
             null,
             2
           )}
      },
          "subCategory": {
            "@type": "Thing",
          "name": ${JSON.stringify(reportDetails?.category_name, null, 2)},
          "identifier": ${JSON.stringify(
            reportDetails?.crimeCategoryID,
            null,
            2
          )}
      },
          "reportedBy": {
            "@type": "Person",
          "name": ${JSON.stringify(reportDetails?.name, null, 2)},
          "alternateName": ${JSON.stringify(reportDetails?.username, null, 2)},
          "image": ${JSON.stringify(reportDetails?.profile_picture, null, 2)}
      },
          "reportAttachments": ${JSON.stringify(attachmentsSchema, null, 2)} ,
          "interactionStatistic": {
            "@type": "InteractionCounter",
          "interactionType": "http://schema.org/ViewAction",
          "userInteractionCount": ${JSON.stringify(
            reportDetails?.view_count,
            null,
            2
          )} 
      },
          "commentCount": ${JSON.stringify(reportDetails?.comment_count)},
          "status": ${JSON.stringify(
            reportDetails?.status == 1 ? "active" : "inactive",
            null,
            2
          )},
          "dateModified": ${JSON.stringify(reportDetails?.updated_at, null, 2)} 
        },
        {
          "@type": "Event",
          "name": ${JSON.stringify(reportDetails?.category_name, null, 2)},
          "startDate": ${JSON.stringify(dateTime, null, 2)},
          "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "location": {
            "@type": "Place",
            "name": ${JSON.stringify(reportDetails?.location, null, 2)},
            "address": {
              "@type": "PostalAddress",
              "streetAddress": ${JSON.stringify(
                reportDetails?.location,
                null,
                2
              )},
              "addressLocality": ${JSON.stringify(
                reportLocation?.addressLocality,
                null,
                2
              )},
              "addressRegion": ${JSON.stringify(
                reportLocation?.addressRegion,
                null,
                2
              )},
              "postalCode": ${JSON.stringify(
                reportLocation?.postalCode,
                null,
                2
              )},
              "addressCountry": ${JSON.stringify(
                reportLocation?.addressCountry,
                null,
                2
              )},
           }
        },
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.crimespotter.app/report/${id}"
          }

          "publisher": {
            "@type": "Organization",
            "name": "Crimespotter",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.crimespotter.app/logoimg.png"
            }
          }

          "isPartOf": {
            "@type": "CreativeWork",
            "name": "Crime Reports",
            "url": "https://www.crimespotter.app/logoimg.png"
          }
    `}
        </script>
      </Helmet>
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <div className={styles.locationreportDetails}>
            <div className={styles.googleMapSet}>
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    left: "10px",
                    bottom: "10px",
                    width: "42px",
                    height: "42px",
                    borderRadius: "50%",
                    backgroundColor: "black",
                    cursor: "pointer",
                    zIndex: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleCircleClick}
                >
                  <img
                    src={CurrentLocation}
                    style={{ width: "25px", height: "25px" }}
                    alt="Current Location"
                  />
                </div>
                <MapView
                  center={center}
                  zoom={12}
                  mapContainerStyle={{
                    height: "271px",
                    width: "100%",
                  }}
                  icon={getIconByCategoryType(reportDetails.crime_type)}
                  reportDetails={reportDetails}
                  markers={mapList}
                />
              </div>
            </div>
          </div>
          <Row>
            <Col lg={8} md={8}>
              <div className={styles.reportLeftSide}>
                <div className={styles.reportDetText}>
                  <h1>{reportDetails && reportDetails.category_name}</h1>
                  <span>
                    {formatDate(reportDetails && reportDetails.date)}&nbsp;{" "}
                    <p>{t("At")} </p>&nbsp;
                    {reportDetails && reportDetails.time
                      ? formatTime(reportDetails.time)
                      : t("UNKNOWN")}
                  </span>
                  <h3>
                    {reportDetails && reportDetails.location !== "undefined"
                      ? reportDetails.location
                      : ""}
                  </h3>
                </div>

                <div className={styles.reportIconData}>
                  <span>
                    <SlEye />
                    <p>{reportDetails && reportDetails.view_count}</p>
                  </span>
                  <span>
                    <p>
                      {t("REPORT")} #{reportDetails && reportDetails.reportID}
                    </p>
                  </span>
                  {reportDetails.reported_by_id &&
                    reportDetails.reported_by_id !== currentUser.userID && (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          flagPopupHandleShow(true);
                        }}
                      >
                        <FaRegFlag />
                        <p>{t("FLAG_REPORT")}</p>
                      </span>
                    )}
                  {reportDetails.reported_by_id &&
                    reportDetails.reported_by_id == currentUser.userID && (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleEdit(reportDetails);
                        }}
                      >
                        <FaRegEdit />
                        <p>{t("EDITS")}</p>
                      </span>
                    )}
                </div>

                <div className={styles.reportMainDesc}>
                  <p>
                    {detailsWithHashtags && (
                      <span
                        style={{ cursor: "pointer" }}
                        ref={containerRef}
                        dangerouslySetInnerHTML={{
                          __html: ChatTextBrTageFormate(detailsWithHashtags),
                        }}
                      />
                    )}
                  </p>
                </div>
              </div>

              {reportDetails &&
                reportDetails.field_type == FIELD_TYPE.PERSON && (
                  <>
                    <div className={styles.persionReportDetails}>
                      <h2>
                        {reportDetails && reportDetails.person_report.full_name}
                      </h2>
                      <span>
                        {reportDetails && reportDetails.person_report && (
                          <>
                            {reportDetails.person_report.gender == 0
                              ? ""
                              : reportDetails.person_report.gender == 1
                              ? `${t("MALE")}`
                              : `${t("FEMALE")}`}
                            {reportDetails.person_report.age != 0 &&
                              ` - ${reportDetails.person_report.age}`}
                            {reportDetails.person_report.ethinicity_name &&
                              ` - ${reportDetails.person_report.ethinicity_name}`}
                          </>
                        )}
                      </span>
                    </div>

                    {reportDetails &&
                      reportDetails.person_report.is_found == 1 && (
                        <div className={styles.persionFound}>
                          <FaRegCircleCheck />
                          <span>{t("FOUND")}</span>
                        </div>
                      )}
                  </>
                )}

              {reportDetails &&
                reportDetails.field_type == FIELD_TYPE.VEHICLE && (
                  <>
                    <div className={styles.vehicleReportDetails}>
                      <div className={styles.persionReportDetails}>
                        <h2>
                          {reportDetails &&
                            reportDetails.vehicle_report.registration}
                        </h2>
                        <span>
                          {reportDetails &&
                            reportDetails.vehicle_report &&
                            [
                              reportDetails.vehicle_report.vehicle_make,
                              reportDetails.vehicle_report.model,
                              reportDetails.vehicle_report.color,
                            ]
                              .filter(Boolean)
                              .join(" - ")}
                        </span>
                      </div>
                    </div>
                  </>
                )}

              {reportDetails &&
              reportDetails.report_attachments &&
              reportDetails.report_attachments.length > 0 ? (
                <div className="imageRowMain">
                  {imageAttachments &&
                    imageAttachments.map((item, index) => (
                      <div className="imageRowMainDiv" key={index}>
                        <LightGallery
                          speed={1000}
                          closable={true}
                          hideBarsDelay={0}
                          controls={false}
                          download={false}
                          counter={false}
                        >
                          <a href={item.attachment_thumb}>
                            <LazyLoadImageComponent
                              src={item.attachment_thumb}
                              width="100"
                              height="100"
                              style={{ cursor: "pointer" }}
                              alt="report image"
                            ></LazyLoadImageComponent>
                          </a>
                        </LightGallery>
                      </div>
                    ))}
                </div>
              ) : (
                <div
                  className="imageRowMain"
                  style={{ display: "initial" }}
                ></div>
              )}

              {videoAttachments && videoAttachments.length > 0 ? (
                <div className="VideoRowMain">
                  {videoAttachments &&
                    videoAttachments.map((item, index) => (
                      <div className="videoContainer" key={index}>
                        <ReactPlayer
                          url={item.attachment_url}
                          controls
                          width="100%"
                          height="100%"
                        />
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}

              <div className={styles.reportSocialIcon}>
                <span
                  onClick={() => {
                    TrendCount(
                      reportDetails && reportDetails.reportID,
                      reportDetails && reportDetails.is_trended == active
                        ? inactive
                        : active
                    );
                  }}
                >
                  {reportDetails.is_trended == active ? (
                    <>
                      <img src={TrendRed} alt="trend icon" />
                      &nbsp;{" "}
                      <div style={{ color: "red" }}>{t("TREND_MARKED")}</div>
                    </>
                  ) : (
                    <>
                      <>
                        <svg
                          className={styles.trendIcon}
                          width="17px"
                          height="16px"
                          viewBox="0 0 17 16"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <title>{t("GROUP")}</title>
                          <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <g
                              id="USER_REPORTS"
                              transform="translate(-613, -525)"
                              stroke="currentColor"
                              strokeWidth="1.56"
                            >
                              <g id="Group" transform="translate(614.52, 526)">
                                <path
                                  d="M6.916,13.832 C3.09639867,13.832 0,10.7356013 0,6.916 C0,3.09639867 3.09639867,0 6.916,0 C10.7356013,0 13.832,3.09639867 13.832,6.916 C13.832,10.7356013 10.7356013,13.832 6.916,13.832 Z"
                                  id="Path"
                                ></path>
                                <polygon
                                  id="Path"
                                  transform="translate(6.9822, 7.7102) rotate(-315) translate(-6.9822, -7.7102)"
                                  points="5.096 5.824 8.86836364 5.824 5.096 5.824 5.096 9.59636364"
                                ></polygon>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </>
                      &nbsp; {t("TREND_COUNT")}
                    </>
                  )}
                </span>
                <span
                  onClick={() => {
                    BookmarkCount(
                      reportDetails && reportDetails.reportID,
                      reportDetails && reportDetails.is_bookmarked == active
                        ? inactive
                        : active
                    );
                  }}
                >
                  {reportDetails.is_bookmarked == active ? (
                    <>
                      <img src={BookmarkRed} alt="bookmark icon" />
                      &nbsp;
                      <div style={{ color: "red" }}>{t("BOOKMARK_MARKED")}</div>
                    </>
                  ) : (
                    <>
                      <>
                        <svg
                          className={styles.icon}
                          width="15px"
                          height="15px"
                          viewBox="0 0 15 15"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <title>{t("ICON")}</title>
                          <g
                            id="Page-1"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <g
                              id="USER_REPORTS"
                              transform="translate(-552, -526)"
                              stroke="currentColor"
                              strokeWidth="1.56"
                            >
                              <path
                                d="M562.668571,532.982857 L562.668571,530.011429 L562.668571,532.982857 L559.697143,532.982857 L562.668571,532.982857 Z M562.668571,532.982857 L562.668571,535.954286 L562.668571,532.982857 L565.64,532.982857 L562.668571,532.982857 Z M562.668571,539.52 L557.914286,535.657143 L553.16,539.52 L553.16,527.04 L562.668571,527.04"
                                id="Icon"
                              ></path>
                            </g>
                          </g>
                        </svg>
                      </>
                      &nbsp;{t("BOOKMARK_COUNT")}
                    </>
                  )}
                </span>
                <>
                  <ShareLink
                    className={styles.shareLinkText}
                    generateUrl={generateUrl}
                  />
                </>
              </div>

              {profileDetails?.length > 0 && (
                <div className={styles.dashboardInfo}>
                  <div
                    className={styles.dashboardImg}
                    style={{ cursor: "pointer", marginRight: "15px" }}
                    onClick={() => TimeLineViewProfile(userId)}
                  >
                    {reportDetails &&
                    reportDetails.reported_as == REPORT_AS.PROFILE ? (
                      <LazyLoadImageComponent
                        src={
                          profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].profile_picture
                        }
                        alt="profile image"
                      ></LazyLoadImageComponent>
                    ) : (
                      <>
                        <LazyLoadImageComponent
                          src={anonymous}
                          className={styles.anonymousImage}
                          alt="anonymous image"
                        ></LazyLoadImageComponent>
                      </>
                    )}
                  </div>
                  {reportDetails &&
                  reportDetails.reported_as == REPORT_AS.PROFILE ? (
                    <div className={styles.dashboardText}>
                      <p
                        onClick={() => TimeLineViewProfile(userId)}
                        style={{ cursor: "pointer" }}
                      >
                        @
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].username}
                      </p>
                      <h3
                        onClick={() => TimeLineViewProfile(userId)}
                        style={{ cursor: "pointer" }}
                      >
                        {profileDetails &&
                          profileDetails.length > 0 &&
                          profileDetails[0].name}
                      </h3>

                      <div className={styles.dashboardInfoBtn}>
                        {currentUser.userID !== profileDetails[0]?.userID &&
                          reportDetails.reported_as !==
                            PRIVATE_POFILE.reported_as && (
                            <>
                              {isFollowing ? (
                                <button
                                  className={styles.followButton}
                                  onClick={() => {
                                    FollowersCount(userId);
                                  }}
                                >
                                  {t("FOLLOWING")}
                                </button>
                              ) : (
                                <button
                                  className={styles.unfollowButton}
                                  onClick={() => {
                                    FollowersCount(userId);
                                  }}
                                >
                                  {t("UNFOLLOWING")}
                                </button>
                              )}
                            </>
                          )}

                        <h5>
                          <span>
                            <strong>
                              {profileDetails &&
                                profileDetails.length > 0 &&
                                profileDetails[0].follower}
                            </strong>{" "}
                            {t("FOLLOWERS")} |{" "}
                          </span>
                          <span>
                            <strong>
                              {profileDetails &&
                                profileDetails.length > 0 &&
                                profileDetails[0].following}
                            </strong>{" "}
                            {t("FOLLOWING")}
                          </span>
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={styles.anonymousTestDetails}>
                        <h3>{t("ANONYMS")}</h3>
                        <p>{t("USER_IDENTITY_WITHELD")}</p>
                      </div>
                    </>
                  )}
                </div>
              )}

              {timeLineData && timeLineData.length > 0 && (
                <>
                  {/* slider */}
                  {timeLineData && timeLineData.length > 1 ? (
                    <div className={styles.reportDetailsSlider}>
                      <div className={styles.reportSlider}>
                        <Slider {...settingsNew}>
                          {timeLineData &&
                            timeLineData.length > 1 &&
                            timeLineData.map((item, index) => (
                              <div key={index} className={styles.reportSlide}>
                                {index % 2 === 0 ? (
                                  <div className={styles.reportLeft}>
                                    <div className={styles.reportInfo}>
                                      <div
                                        className={styles.reportText}
                                        style={{ marginRight: "28px" }}
                                        onClick={() =>
                                          TimeLineViewDetails(item.reportID)
                                        }
                                      >
                                        <h3>{item.category_name}</h3>

                                        <span
                                          className={styles.sliderFormateDate}
                                        >
                                          {item && item.date != "" ? (
                                            <>{formatDate(item && item.date)}</>
                                          ) : (
                                            ""
                                          )}

                                          {item && item.time != "" ? (
                                            <>
                                              &nbsp; <p>{t("At")} </p>&nbsp;
                                              {item && item.time
                                                ? formatTime(item.time)
                                                : t("UNKNOWN")}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        <p>{item.location}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className={styles.reportRight}>
                                    <div
                                      className={styles.reportRightSliderText}
                                    >
                                      <div className={styles.reportInfo}>
                                        <div
                                          className={styles.reportText}
                                          onClick={() =>
                                            TimeLineViewDetails(item.reportID)
                                          }
                                        >
                                          <h3>{item.category_name}</h3>
                                          <span
                                            className={styles.sliderFormateDate}
                                          >
                                            {item && item.date != "" ? (
                                              <>
                                                {formatDate(item && item.date)}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {item && item.time != "" ? (
                                              <>
                                                &nbsp; <p>{t("At")} </p>&nbsp;
                                                {item && item.time
                                                  ? formatTime(item.time)
                                                  : t("UNKNOWN")}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <p>{item.location}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.reportAdds}>
                      <div
                        onClick={() =>
                          TimeLineViewDetails(
                            timeLineData && timeLineData[0]?.reportID
                          )
                        }
                      >
                        <h3>
                          {timeLineData && timeLineData[0]?.category_name}
                        </h3>
                        <span>
                          {formatDateTimeSlider(
                            timeLineData && timeLineData[0]?.created_at
                          )}
                        </span>
                        <p>{timeLineData && timeLineData[0]?.location}</p>
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* ----comments----- */}
              <div className="commentsMainDiv">
                <div className="commentsCountMain">
                  <div className="commentsCountIcon">
                    <img src={chatMessageIcon} alt="icon" />
                    &nbsp; &nbsp;
                  </div>

                  <div className="commentsCountRight">
                    <span>
                      {t("COMMENTS")}&nbsp;(
                      <p>
                        {commentDataList && commentDataList.total_reports
                          ? commentDataList.total_reports
                          : "0"}
                      </p>
                      )&nbsp;
                    </span>
                  </div>
                </div>

                {commentDataList && commentDataList.data_found == false ? (
                  <div className="commentsDescription">
                    <span>
                      <h2> {t("NO_COMMENTS_YET")}</h2>{" "}
                      {t("DID_YOU_SEE_SOMETHING")}
                    </span>
                    <span> {t("CAN_YOU_OFFER_INFOMATION_ON")} </span>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* left chat */}
              {commentDataList && commentDataList.data_found == true ? (
                <div className={styles.scrollTopChat} ref={chatContainerRef}>
                  <div className="commTextDivNew">
                    {commentData &&
                      commentData
                        .slice()
                        .reverse()
                        .map((item, index) => {
                          return item?.commented_by_id != currentUser.userID ? (
                            <div className="commTextDivInner" key={index}>
                              <div className="imgIcon">
                                <LazyLoadImageComponent
                                  src={item.profile_picture}
                                  alt="icon"
                                ></LazyLoadImageComponent>
                              </div>
                              <div className="messageIcon">
                                <div className="messageIconInner">
                                  <h2>{item.comment}</h2>

                                  {item &&
                                  item.attachment_url &&
                                  !item.video_thumb_url ? (
                                    <>
                                      {item && item.attachment_url ? (
                                        <div>
                                          <LightGallery
                                            speed={500}
                                            closable={true}
                                            hideBarsDelay={0}
                                            controls={false}
                                            download={false}
                                            counter={false}
                                          >
                                            <a href={item.attachment_url}>
                                              <LazyLoadImageComponent
                                                style={{ cursor: "pointer" }}
                                                src={item.attachment_url}
                                                alt="comment image"
                                              ></LazyLoadImageComponent>
                                            </a>
                                          </LightGallery>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {item &&
                                      item.attachment_url &&
                                      item.video_thumb_url ? (
                                        <div
                                          className={
                                            styles.videoContainerComment
                                          }
                                        >
                                          <ReactPlayer
                                            url={item.attachment_url}
                                            controls
                                            width="100%"
                                            height="100%"
                                            className="react-player"
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                  <div className="messageIconShape"></div>
                                </div>
                                <p>
                                  {" "}
                                  @{item.username} -{" "}
                                  {formatUpdatedDate(item.updated_at)}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="commTextReply" key={index}>
                              <div className="imgIcont">
                                <span></span>
                              </div>
                              <div className="commTextDivInner">
                                <div className="messageIcon">
                                  <div className="messageIconInner">
                                    <h2
                                      dangerouslySetInnerHTML={{
                                        __html: ChatTextBrTageFormate(
                                          item.comment
                                        ),
                                      }}
                                    ></h2>
                                    {item &&
                                    item.attachment_url &&
                                    !item.video_thumb_url ? (
                                      <>
                                        {item && item.attachment_url ? (
                                          <div className="lightGalleryMain">
                                            <LightGallery
                                              speed={500}
                                              closable={true}
                                              hideBarsDelay={0}
                                              controls={false}
                                              download={false}
                                              counter={false}
                                            >
                                              <a href={item.attachment_url}>
                                                <LazyLoadImageComponent
                                                  style={{ cursor: "pointer" }}
                                                  src={item.attachment_url}
                                                  alt="chat image"
                                                ></LazyLoadImageComponent>
                                              </a>
                                            </LightGallery>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {item &&
                                        item.attachment_url &&
                                        item.video_thumb_url ? (
                                          <div
                                            className={
                                              styles.videoContainerComment
                                            }
                                          >
                                            <ReactPlayer
                                              url={item.attachment_url}
                                              controls
                                              width="100%"
                                              height="100%"
                                              className="react-player"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}

                                    <div className="messageIconShapeRight"></div>
                                  </div>
                                  <p>
                                    @{item.username} -{" "}
                                    {formatUpdatedTime(item.updated_at)}
                                  </p>
                                </div>
                                <div className="imgIconRight">
                                  <LazyLoadImageComponent
                                    src={item.profile_picture}
                                    alt="profile"
                                  ></LazyLoadImageComponent>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    <>
                      {commentData?.length < totalReports ? (
                        <div className={styles.loadMoreSectionComment}>
                          <span onClick={loadMoreReports}>
                            {t("LOAD_MORE_COMMENTS")}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* ----comments----- */}
              <div className="commentsMainDiv">
                <div className="selectimageshow">
                  {previewImage && (
                    <div className="showSelectedImg">
                      <div className="showSelectedImgInner">
                        {previewImage.type === "image" ? (
                          <LightGallery
                            speed={500}
                            closable={true}
                            hideBarsDelay={0}
                            controls={false}
                            download={false}
                            counter={false}
                          >
                            <a href={previewImage}>
                              <LazyLoadImageComponent
                                src={previewImage}
                                style={{ cursor: "pointer" }}
                                alt="chat previewimage"
                              ></LazyLoadImageComponent>
                            </a>
                          </LightGallery>
                        ) : (
                          <LightGallery
                            speed={500}
                            closable={true}
                            hideBarsDelay={0}
                            controls={false}
                            download={false}
                            counter={false}
                          >
                            <a href={previewImage}>
                              <LazyLoadImageComponent
                                src={previewImage}
                                style={{ cursor: "pointer" }}
                                alt="chat image"
                              ></LazyLoadImageComponent>
                            </a>
                          </LightGallery>
                        )}
                        <div className="imageCrossIcon">
                          <ImCross onClick={handleRemoveMedia} />
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedVideo && (
                    <div className="showSelectedImg">
                      <div className="showSelectedImgInner">
                        {selectedVideo.type === "video" ? (
                          <ReactPlayer
                            url={selectedVideo.url}
                            controls
                            width="100%"
                            height="100%"
                            onClick={() => window.open(selectedVideo.url)}
                          />
                        ) : (
                          <ReactPlayer
                            url={selectedVideo.url}
                            controls
                            width="100%"
                            height="100%"
                            onClick={() => window.open(selectedVideo.url)}
                          />
                        )}
                        <div className="imageCrossIcon">
                          <ImCross onClick={handleRemoveMedia} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* ---chat--input */}
                <div className="commentsInput">
                  <div className="commentsInputLeft">
                    <FaCirclePlus
                      onClick={() => fileInputRef.current.click()}
                    />
                    <input
                      type="file"
                      accept="image/*,video/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={(e) => handleFileChange(e)}
                    />
                  </div>

                  <div className="commentsInputCenter">
                    {" "}
                    <Form>
                      <Form.Control
                        as="textarea"
                        placeholder={t("WRITE_A_COMMENT")}
                        rows={1}
                        name="comment"
                        value={comment}
                        onChange={handleCommentChange}
                        ref={textareaRef}
                        style={{
                          overflow: "auto",
                          resize: "none",
                          backgroundColor: "#efefef",
                          border: "none",
                        }}
                      />
                    </Form>
                  </div>

                  <span>
                    <FaChevronRight onClick={handleSubmit(onSubmit)} />
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div className={styles.googleMapRightMain}>
                <div className={styles.googleMapRight}>
                  <MapView
                    center={{
                      lat: Number(reportDetails.latitude),
                      lng: Number(reportDetails.longitude),
                    }}
                    zoom={16}
                    mapContainerStyle={{
                      height: "336px",
                      width: "100%",
                    }}
                    reportDetails={reportDetails}
                    markers={[reportDetails]}
                    streetViewVisible={streetViewVisible}
                  />
                </div>
                <button onClick={() => setStreetViewVisible(false)}>
                  {t("MAP_VIEW")}
                </button>{" "}
                <span>|</span>
                <button onClick={() => setStreetViewVisible(true)}>
                  {t("STREER_VIEW")}
                </button>
              </div>

              <div className={styles.reportRightAd}>
                {" "}
                Google Adsense Advert 336x280
              </div>
            </Col>
          </Row>
          <div className={styles.reportLeftAd}>
            Google Adsense Advert - 970x250px <br /> Centre horizontally
            <>
              <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-3446097307175125"
                data-ad-slot="8452272640"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
            </>
          </div>
        </Container>
      </div>

      <FlagPopup
        flagShow={flagShow}
        flagPopupHandleClose={flagPopupHandleClose}
        reportDetails={reportDetails}
        ThanksflagHandleShow={ThanksflagHandleShow}
        setThanksflagShow={setThanksflagShow}
      />
      <FlagClosePopup
        thanksflagShow={thanksflagShow}
        ThanksflagHandleClose={ThanksflagHandleClose}
      />

      <CropImg
        handleClose={handleClose}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setPreviewImage}
        aspectRatio={1}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
        onImageChange={handleCropComplete}
      />
    </div>
  );
}

export default ReportDetails;
