import React, { useEffect } from "react";
import branch from "branch-sdk";
import { IoLogoFacebook, IoLogoWhatsapp } from "react-icons/io";
import { FaSquareXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const ShareLink = ({ reportDetails, generateUrl }) => {
  const { t } = useTranslation();
  const YOUR_BRANCH_KEY = "key_test_iEeP4kBjdJGyPW6natHUqjgcrrm6eLO4";

  // Create Branch link
  const createBranchLink = (callback) => {
    const linkData = {
      tags: ["tag1", "tag2"],
      channel: "website",
      feature: "share",
      stage: "new user",
      campaign: "content 123",
      alias: "content_alias", // Optional: Custom alias
      data: {
        $desktop_url: "https://yourwebsite.com",
        $ios_url: "yourapp://ios",
        $android_url: "yourapp://android",
        custom_data: "value",
      },
    };

    branch.link(linkData, (err, url) => {
      if (err) {
        console.error("Branch link creation error:", err);
      } else {
        console.log("Branch deep link created:", url);
        // You can now use this URL for sharing
      }
    });
  };

  return (
    <>
      <span>{t("SHARE")}</span>
      <span>
        <FacebookShareButton
          url={generateUrl}
          title="We've identified a recent report on CrimeSpotter that may be of interest to you. For detailed information, please visit the following link"
          separator=" : "
        >
          <IoLogoFacebook />
        </FacebookShareButton>
      </span>
      <span>
        <TwitterShareButton
          url={generateUrl}
          title="We've identified a recent report on CrimeSpotter that may be of interest to you. For detailed information, please visit the following link"
          separator=" : "
        >
          <FaSquareXTwitter style={{ width: "25px", height: "25px" }} />
        </TwitterShareButton>
      </span>
      <span>
        <WhatsappShareButton
          url={generateUrl}
          title="We've identified a recent report on CrimeSpotter that may be of interest to you. For detailed information, please visit the following link"
          separator=" : "
        >
          <IoLogoWhatsapp />
        </WhatsappShareButton>
      </span>
    </>
  );
};

export default ShareLink;
